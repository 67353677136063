import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import {title} from "assets/jss/material-kit-react.jsx";

const workStyle = {
  section: {
    padding: "50px 0"
  },
  title: {
    ...title,
    marginBottom: "20px",
    marginTop: "20px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  requirementList: {
    textAlign: "center",
    color: "#999",
    // listStylePosition: "inside",
    paddingInlineStart: 0,
    listStyle: "none",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  }
};

class PositionsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h3 className={classes.title}>Frontend Developer</h3>
            <p className={classes.description}>We are currently looking for a frontend developer with the following skillset</p>
            <ul className={classes.requirementList}>
              <li>ReactJS</li>
              <li>Redux</li>
              <li>GIT</li>
              <li>PostgreSQL</li>
            </ul>
            <h4 className={classes.description}>Please apply with CV to <a href = "mailto: career@nari.ge">career@nari.ge</a></h4>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <h3 className={classes.title}>Backend Developer</h3>
            <p className={classes.description}>Senior backend developer wanted</p>
            <ul className={classes.requirementList}>
              <li>Java, Spring Boot, Hibernate</li>
              <li>GIT</li>
              <li>SQL Databases</li>
            </ul>
            <h4 className={classes.description}>Please apply with CV to <a href = "mailto: career@nari.ge">career@nari.ge</a></h4>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <h3 className={classes.title}>Fullstack Developer</h3>
            <p className={classes.description}>Senior backend developer wanted</p>
            <ul className={classes.requirementList}>
              <li>Java, Spring Boot, Hibernate</li>
              <li>Amazon AWS</li>
              <li>Python</li>
              <li>ReactJS or Angular</li>
              <li>GIT</li>
              <li>SQL and NoSQL Databases</li>
            </ul>
            <h4 className={classes.description}>Please apply with CV to <a href = "mailto: career@nari.ge">career@nari.ge</a></h4>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(PositionsSection);
